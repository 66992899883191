import React from 'react';
import { Link } from 'gatsby';

import Layout from '../components/layout';
import Image from '../components/image';
import MaritHelen from '../components/marit-helen';
import SveinEgil from '../components/svein-egil';
import Petter from '../components/petter';
import Daniel from '../components/daniel';
import SEO from '../components/seo';
import Website from '../../assets/images/website.svg';
import Usability from '../../assets/images/usability.svg';
import Developer from '../../assets/images/developer.svg';
import Mobile from '../../assets/images/mobile_app.svg';
import Creative from '../../assets/images/creative.svg';
import Reviews from '../../assets/images/reviews.svg';
import Contact from '../../assets/images/contact.svg';
import { motion } from 'framer-motion';
import VisibilitySensor from 'react-visibility-sensor';
import Recaptcha from 'react-recaptcha';
import axios from 'axios';
import { ClipLoader } from 'react-spinners';


const svgAboutUsVariant = {
  ready: {
    opacity: 1,
    transition: {
      ease: 'easeIn',
      duration: 0.6,
    },
  },
  unready: {
    opacity: 0,
  },
};

const aboutUsVariant = {
  ready: {
    opacity: 1,
    y: 0,
    transition: {
      delay: 0.2,
    },
  },
  unready: {
    y: -100,
    opacity: 0,
  },
};

const reviewsVariant = {
  ready: {
    opacity: 1,
    transition: { delay: 0.05, staggerChildren: 0.5, delayChildren: 0.15 },
  },
  unready: {
    opacity: 0,
    transition: { staggerChildren: 0.05, staggerDirection: -1 },
  },
};

const reviewerVariant = {
  ready: {
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -100 },
    },
  },
  unready: {
    y: 50,
    opacity: 0,
    transition: {
      y: { stiffness: 1000 },
    },
  },
};

export default class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isReady: false,
      aboutUsVisibility: false,
      reviewVisibility: false,
      contactVisibility: false,
      name: '',
      email: '',
      phone: '',
      message: '',
      busy: false,
      errors: {},
      error: false,
      success: false,
    };
  }

  componentDidMount() {
    this.setState({
      isReady: true,
    });
  }

  aboutUsVisibility = isVisible => {
    this.setState(() => ({ aboutUsVisibility: isVisible }));
  };

  reviewVisibility = isVisible => {
    this.setState(() => ({ reviewVisibility: isVisible }));
  };

  contactVisibility = isVisible => {
    this.setState(() => ({ contactVisibility: isVisible }));
  };

  handleInputChange = event => {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  };

  clearErrors = () => {
    this.setState(() => ({ errors: {} }));
  };

  clearErrorSuccess = () => {
    this.setState(() => ({ error: false, success: false }));
  };

  handleSubmit = event => {
    this.setState(() => ({ busy: true, errors: {}, error: null, success: null }));
    event.preventDefault();
    let axiosConfig = {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
    };
    const obj = {};
    if (!this.state.name || this.state.name.length <= 0) {
      obj.name = 'Du må skrive inn et navn.';
    }

    if (!this.state.phone || this.state.phone.length <= 0) {
      obj.phone = 'Du må skrive inn et telefonnummer.';
    }

    if (!this.state.email || this.state.email.length <= 0) {
      obj.email = 'Du må skrive inn en E-post.';
    }

    if (!this.state.message || this.state.message.length <= 0) {
      obj.message = 'Du må skrive inn en melding.';
    }

    if (Object.keys(obj).length) {
      this.setState(() => ({ busy: false, errors: obj }));
      return;
    }
    let oAuthUrl = `${process.env.EMAIL_URL}/oauth/token`;
    let formUrl = `${process.env.EMAIL_URL}/api/form-responses`;
    let token = null;
    axios
      .post(
        oAuthUrl,
        {
          grant_type: 'client_credentials',
          client_id: process.env.CLIENT_ID,
          client_secret: process.env.CLIENT_SECRET,
        },
        axiosConfig,
      )
      .then(response => {
        token = response.data.access_token;
      })
      .then(res => {
        if (token) {
          axiosConfig.Authorization = `Bearer ${token}`;
          axios
            .post(
              formUrl,
              {
                name: this.state.name,
                email: this.state.email,
                phone: this.state.phone,
                message: this.state.message,
              },
              {headers: axiosConfig},
            )
            .then(response => {
              this.setState(() => ({
                busy: false,
                success: 'Meldingen din ble sendt. Du hører fra oss.',
                name: '',
                email: '',
                phone: '',
                message: ''
              }));
            })
            .catch(error => {
              this.setState(() => ({
                busy: false,
                error: 'Noe gikk galt. Fikk ikke sendt inn meldingen.',
              }));
            });
        }
      })
      .catch(error => {
        this.setState(() => ({
          busy: false,
          error: 'Noe gikk galt. Fikk ikke sendt inn meldingen.',
        }));
      });
  };


  render() {
    return (
      <Layout>
        <SEO title="Hjem" />
        <VisibilitySensor>
          <div id="hva-vi-leverer" className="mt-12 container mx-auto py-48 lg:px-0 px-4">
            <div>
              <h1 className="font-bold uppercase text-gray-700 mb-1 text-xs tracking-wider mb-2 text-center lg:text-left">
                Hva vi leverer
              </h1>
            </div>
            <div className="-mx-2 flex flex-wrap ">
              <div className="px-2 w-full lg:w-1/2 text-center lg:text-left">
                <h2 className="font-bold text-3xl text-gray-800">
                  Nettsider, mobilapplikasjoner og webapplikasjoner.
                </h2>
              </div>

              <div className="px-2 w-full lg:w-1/2 text-center lg:text-left text-sm text-gray-800 font-thin mt-2">
                Vi tilbyr digitale løsninger som nettsider, webapplikasjoner og mobilapplikasjoner.
                Vi benytter oss av fersk teknologi, for å kunne tilby deg den aller beste løsningen.
                Vi er opptatt av at løsningen skal være løst på den beste tekniske måten, og
                samtidig være brukervennlig og veldesignet. Vi leverer kvalitet &mdash; og litt
                ekstra.
              </div>
            </div>
            <div className="flex flex-wrap -mx-4 mt-24">
              <div className="w-1/2 lg:w-1/4 px-4">
                <div className="border-t-2 pt-6 border-blue-600">
                  <Website className="h-20 lg:h-32 w-auto mx-auto"></Website>
                  <div className="mt-4 text-center">
                    <h3 className="font-bold text-gray-800 text-sm break-words lg:text-lg">Nettsider</h3>
                    <div className="mt-2 font-thin text-gray-800 text-sm break-words lg:break-normal">
                      CraftCMS, Søkemotoroptimalisering, Brukervennlighet, Godt design, Optimalisert
                      lastetid, Sikker drifting.
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-1/2  lg:w-1/4 px-4">
                <div className="border-t-2 pt-6 border-blue-600">
                  <Developer className="h-20 lg:h-32 w-auto mx-auto"></Developer>
                  <div className="mt-4 text-center">
                    <h3 className="font-bold text-gray-800 text-sm break-words ">Webapplikasjoner</h3>
                    <div className="mt-2 font-thin text-gray-800 text-sm break-words lg:break-normal">
                      Laravel, Skreddersøm, Brukervennlighet, Godt design, Optimalisert lastetid,
                      Sikker drifting, IT-sikkerhet.
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-1/2  lg:w-1/4 px-4">
                <div className="border-t-2 pt-6 border-blue-600  lg:mt-0 mt-12">
                  <Mobile className="h-20 lg:h-32 w-auto mx-auto"></Mobile>
                  <div className="mt-4 text-center">
                    <h3 className="font-bold text-gray-800 text-sm break-words lg:text-lg">Mobilapplikasjon</h3>
                    <div className="mt-2 font-thin text-gray-800 text-sm break-words lg:break-normal">
                      React-Native, Brukervennlighet, Godt design, App Store, Google Play
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-1/2  lg:w-1/4 px-4">
                <div className="border-t-2 pt-6 border-blue-600  lg:mt-0 mt-12">
                  <Usability className="h-20 lg:h-32 w-auto mx-auto"></Usability>
                  <div className="mt-4 text-center">
                    <h3 className="font-bold text-gray-800 text-sm break-words lg:text-lg">Interaksjonsdesign</h3>
                    <div className="mt-2 font-thin text-gray-800 text-sm break-words lg:break-normal">
                      Utviklings- og designprosess, Gjennomtenkte løsninger, Brukertesting, Fokus på
                      sluttbrukeren
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </VisibilitySensor>
        <VisibilitySensor partialVisibility={true} onChange={this.aboutUsVisibility}>
          <div id="hvem-er-vi" className="bg-gray-100 py-48">
            <div className="container mx-auto">
              <div className="flex flex-wrap  lg:-mx-2 px-4 lg:px-0 items-center">
                <motion.div
                  className="flex"
                  animate={this.state.aboutUsVisibility ? 'ready' : 'unready'}
                  variants={svgAboutUsVariant}
                  initial="unready"
                  className="w-full lg:w-1/2 px-2 order-2 lg:order-none"
                >
                  <Creative className="w-full sm:w-120 sm:mx-auto h-auto"></Creative>
                </motion.div>
                <motion.div
                  animate={this.state.aboutUsVisibility ? 'ready' : 'unready'}
                  variants={aboutUsVariant}
                  initial="unready"
                  className="w-full lg:w-1/2 px-2 order-1 lg:order-none lg:mb-0 mb-8"
                >
                  <div>
                    <div className="lg:text-left text-center">
                      <h1 className="font-bold uppercase text-gray-700 mb-1 text-xs tracking-wider mb-2">
                        Hvem er vi
                      </h1>
                      <h2 className="font-bold text-3xl text-gray-800">
                        En kreativ og trygg teknologipartner for din bedrift
                      </h2>
                    </div>
                    <div className="mt-6 font-thin text-gray-800 text-sm lg:text-left text-center">
                      Vi er et utviklingsbyrå, som utvikler, rådgir, designer og drifter digitale
                      løsninger. Vi holder til i Svolvær i Lofoten, på havnepromenaden. Vi har
                      kanskje byens beste utsikt fra våre kontorlokaler &mdash; så kom gjerne innom
                      på en kopp kaffe.
                    </div>
                  </div>
                  <div className="mt-12">
                    <h1 className="font-bold uppercase text-gray-700 mb-1 text-xs tracking-wider lg:text-left text-center">
                      Ansatte
                    </h1>
                    <div className="mt-6">
                      <div className="flex items-center">
                        <div>
                          <div className="w-24 rounded-full border-2 border-yellow-500">
                            <Image />
                          </div>
                        </div>

                        <div className="ml-8">
                          <div className="text-gray-700 font-bold text-sm">Marius Engen Haugen</div>
                          <div className="mt-1 font-thin text-gray-800 text-sm">
                            Marius er daglig leder, og utvikler hos Naust Interaktiv. 10+ års
                            erfaring som utvikler, og har bachelor i webutvikling og master i
                            Interaksjonsdesign (brukervennlighet) fra NTNU Gjøvik.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </motion.div>
              </div>
            </div>
          </div>
        </VisibilitySensor>
        <VisibilitySensor partialVisibility={true} onChange={this.reviewVisibility}>
          <div id="referanser" className="py-48">
            <div className="container mx-auto">
              <div className="flex flex-wrap lg:-mx-2 px-4 lg:px-0 items-center">
                <motion.div
                  animate={this.state.reviewVisibility ? 'ready' : 'unready'}
                  variants={aboutUsVariant}
                  initial="unready"
                  className="w-full lg:w-1/2 pl-2 lg:pr-12 lg:mb-0 mb-12 break-words lg:break-normal"
                >
                  <div>
                    <div className="lg:text-left text-center">
                      <h1 className="font-bold uppercase text-gray-700 mb-1 text-xs tracking-wider mb-2">
                        Referanser
                      </h1>
                      <h2 className="font-bold text-3xl text-gray-800">
                        Vi digger våre kunder og samarbeidspartnere
                      </h2>
                    </div>
                    <div className="mt-6 font-thin text-gray-800 text-sm lg:text-left text-center">
                      Og vi gjør alt vi kan for at de skal digge oss tilbake. Se hva noen av
                      våre kunder og samarbeidspartnere har å si om oss.
                    </div>
                  </div>
                </motion.div>
                <motion.div
                  className="flex"
                  animate={this.state.reviewVisibility ? 'ready' : 'unready'}
                  variants={svgAboutUsVariant}
                  initial="unready"
                  className="w-full lg:w-1/2 px-2"
                >
                  <Reviews className="w-full sm:w-120 sm:mx-auto md:mx-0 h-auto ml-auto"></Reviews>
                </motion.div>
              </div>
              <motion.div
                variants={reviewsVariant}
                initial="unready"
                animate={this.state.reviewVisibility ? 'ready' : 'unready'}
                className="flex flex-wrap lg:-mx-11 px-4 mt-32"
              >
                <motion.div
                  variants={reviewerVariant}
                  className="w-full lg:w-1/2 mb-12 lg:mb-0 px-4 lg:px-12"
                >
                  <div className="flex flex-col sm:flex-row">
                    <div>
                      <div className="w-24 rounded-full border-2 border-yellow-500 mb-6 sm:mb-0">
                        <MaritHelen />
                      </div>
                    </div>

                    <div className="sm:ml-8">
                      <div className="text-gray-700 font-bold text-sm">Marit-Helen Kåring</div>
                      <div className="mt-1 font-thin text-gray-800 text-sm">
                        <p className="mb-4">
                          Jeg ønsker å gi mine beste anbefalinger av Marius Engen Haugen. Han er
                          faglig dyktig, intelligent og oppfatter dermed godt mine behov og ser
                          helhet. Han er lett å kommunisere med, tar initiativ og leverer til tiden.
                          Han er faglig og personlig trygghet nok til å komme med innspill, stiller
                          nødvendige kritiske synspunkter slik at resultatet ble bedre enn forventet
                          og har gitt mange positive effekter i mitt kundearbeid.
                        </p>
                        <p className="mb-4">
                          Han anbefales på det sterkeste, jeg stiller gjerne som referanse på
                          telefon.
                        </p>
                        <p className="italic font-bold text-gray-700 text-xs">
                          Kåring – HR Consulting AS
                          <br />
                          Marit-Helen Kåring
                          <br />
                          Daglig leder/eier
                        </p>
                      </div>
                    </div>
                  </div>
                </motion.div>
                <motion.div
                  variants={reviewerVariant}
                  className="w-full lg:w-1/2 mb-8 lg:mb-0 mt-12 lg:mt-0  px-4 lg:px-12"
                >
                  <div className="flex flex-col sm:flex-row">
                    <div>
                      <div className="w-24 rounded-full border-2 border-yellow-500 mb-6 sm:mb-0">
                        <Petter />
                      </div>
                    </div>

                    <div className="sm:ml-8">
                      <div className="text-gray-700 font-bold text-sm">Petter Erlbeck</div>
                      <div className="mt-1 font-thin text-gray-800 text-sm">
                        <p className="mb-4">
                          Høllafæst og Hølla Events As har samarbeidet med Naust Interaktiv siden
                          vår oppstart i 2018. Vi har fått levert hjemmesider av høy kvalitet og
                          digitale løsninger i forskjellige varianter som har gjort arbeidshverdagen
                          vår enklere. Vi er strålende fornøyd med jobben Marius har gjort for oss,
                          og vi ønsker absolutt å anbefale tjenestene hans. Effektivitet,
                          pålitelighet, forståelse og kundeorientert er stikkord som oppsummerer
                          Naust Interaktiv og Marius.
                        </p>
                        <p className="mb-4 italic font-bold text-gray-700 text-xs">
                          Høllafæst
                          <br />
                          Petter Erlbeck
                          <br />
                          Festivalsjef
                        </p>
                      </div>
                    </div>
                  </div>
                </motion.div>
                <motion.div
                  variants={reviewerVariant}
                  className="w-full lg:w-1/2 mb-8 lg:mb-0  px-4 lg:px-12 mt-12"
                >
                  <div className="flex flex-col sm:flex-row">
                    <div>
                      <div className="w-24 h-24 rounded-full border-2 border-yellow-500 mb-6 sm:mb-0">
                        <Daniel></Daniel>
                      </div>
                    </div>

                    <div className="sm:ml-8">
                      <div className="text-gray-700 font-bold text-sm">Daniel Sandsleth</div>
                      <div className="mt-1 font-thin text-gray-800 text-sm">
                        <p className="mb-4">
                        Naust Interaktiv har siden 2018 vært leverandør av støttesystem for våre hovedaktiviteter – utbygging av telekom infrastruktur. Marius Haugen har utviklet verktøyet Nettflyt for oss, og løst alle våre behov og ønsker på en måte som langt overgår forventningene. Han har en unik evne til å sette seg inn i våre problemstillinger, og til å bruke sin kreativitet, innovasjon og teknisk ekspertise til å levere løsninger for oss. Nettmontasje har hatt et formidabelt løft på kvalitet og effektivitet i sine prosjekter på bakgrunn av løsningene fra Naust Interaktiv. Vi vil anbefale Marius til alle som måtte ha behov for de tjenestene som Naust Interaktiv leverer.
                        </p>
                        <p className="mb-4 italic font-bold text-gray-700 text-xs">
                          Nettmontasje AS
                          <br />
                          Daniel Sandsleth
                          <br />
                          Daglig leder
                        </p>
                      </div>
                    </div>
                  </div>
                </motion.div>
                <motion.div
                  variants={reviewerVariant}
                  className="w-full lg:w-1/2 mb-8 lg:mb-0  px-4 lg:px-12 mt-12"
                >
                  <div className="flex flex-col sm:flex-row">
                    <div>
                      <div className="w-24 rounded-full border-2 border-yellow-500  mb-6 sm:mb-0">
                        <SveinEgil />
                      </div>
                    </div>

                    <div className="sm:ml-8">
                      <div className="text-gray-700 font-bold text-sm">Svein Egil Torbergsen</div>
                      <div className="mt-1 font-thin text-gray-800 text-sm">
                        <p className="mb-4">
                          Vi på Svolvær IL A-lag har hatt et samarbeid med Marius og Naust
                          Interaktiv i 2018 og 2019. Og vi kunne ikke vært mer fornøyd! Marius er
                          tilgjengelig hele tiden, svarer og leverer kjapt. Og viktigst av alt, han
                          leverer kvalitet. På toppen av det hele er Marius en bra kar! Vi kan
                          anbefale Naust Interaktiv på det varmeste!
                        </p>
                        <p className="mb-4 italic font-bold text-gray-700 text-xs">
                          Svein Egil Torbergsen
                          <br/>
                          Svolvær IL A-lag
                        </p>
                      </div>
                    </div>
                  </div>
                </motion.div>
              </motion.div>
            </div>
          </div>
        </VisibilitySensor>
        <VisibilitySensor partialVisibility={true} onChange={this.contactVisibility}>
          <div id="kontakt-oss" className="py-48 bg-gray-100">
            <div className="container mx-auto">
              <div className="flex flex-wrap lg:-mx-2 px-4 lg:px-0 items-center">
                <motion.div
                  className="flex"
                  animate={this.state.contactVisibility ? 'ready' : 'unready'}
                  variants={svgAboutUsVariant}
                  initial="unready"
                  className="w-full lg:w-1/2 px-2 mb-12 lg:mb-0"
                >
                  <Contact className="w-full sm:w-120 sm:mx-auto h-auto"></Contact>
                </motion.div>
                <motion.div
                  animate={this.state.contactVisibility ? 'ready' : 'unready'}
                  variants={aboutUsVariant}
                  initial="unready"
                  className="w-full lg:w-1/2 px-2"
                >
                  <div>
                    <div className="text-center lg:text-left">
                      <h1 className="font-bold uppercase text-gray-700 mb-1 text-xs  tracking-wider mb-2">
                        Kontakt oss
                      </h1>
                      <h2 className="font-bold text-3xl text-gray-800">
                        Ønsker du en kreativ samarbeidspartner, kvalitet og personlig oppfølging?
                      </h2>
                    </div>
                    <div className="mt-6 font-thin text-gray-800 text-sm text-center lg:text-left">
                      Da er vi firmaet for deg. Kontakt oss gjerne for en hyggelig og uforpliktende prat. Enten gjennom skjemaet under, send oss en melding på <a className="text-blue-700" href="mailto:marius@naustinteraktiv.no">marius@naustinteraktiv.no</a>, eller ring oss på <a className="text-blue-700" href="tel:97428808">97 42 88 08</a>. Vi gleder oss til å høre fra deg.
                    </div>
                    <div className="mt-6 font-thin text-gray-800 text-sm">
                      {(this.state.success ||
                        this.state.error) && (
                          <div
                            className={`${
                              this.state.error ? 'bg-red-100 border-red-400 text-red-700' : ''
                            } ${
                              this.state.success
                                ? 'bg-green-100 border-green-400 text-green-700'
                                : ''
                            } border px-4 py-3 rounded relative mb-4`}
                            role="alert"
                          >
                            <strong className="font-bold">
                              {this.state.error && 'Farsken ailtså! '}
                              {this.state.success && 'Hurra! '}
                            </strong>
                            <span className="block sm:inline">
                              {this.state.error && this.state.error}{' '}
                              {this.state.success && this.state.success}
                            </span>
                            <span className="absolute top-0 bottom-0 right-0 px-4 py-3">
                              <button type="button" aria-label="Fjern feilbeskjeder" onClick={this.clearErrorSuccess}>
                                <svg
                                  className={`fill-current h-6 w-6 ${this.state.error ? 'text-red-500' : ''} ${this.state.success ? 'text-green-500' : ''}`}
                                  role="button"
                                  aria-label="Fjern feilbeskjeder"
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 20 20"
                                >
                                  <title>Close</title>
                                  <path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" />
                                </svg>
                              </button>
                            </span>
                          </div>
                        )}
                      <form method="post" action="#">
                      <Recaptcha sitekey="6LcN6LsUAAAAAKxRqO5aO9p4c__SUecxqtsJUIe3" />
                        <div className="mb-4">
                          <label
                            className="block text-gray-700 text-sm font-bold mb-2"
                            htmlFor="name"
                          >
                            Navn
                          </label>
                          <input
                            className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
                              this.state.errors.name ? 'border-red-500' : ''
                            }`}
                            id="name"
                            type="text"
                            placeholder="Navn"
                            name="name"
                            value={this.state.name}
                            onChange={this.handleInputChange}
                            required
                          />
                          {this.state.errors.name && (
                            <p className="text-red-500 text-xs italic">Vennligst sktriv inn navn.</p>
                          )}
                        </div>
                        <div className="mb-4">
                          <div className="flex flex-wrap -mx-2">
                            <div className="w-1/2 px-2">
                              <label
                                className="block text-gray-700 text-sm font-bold mb-2"
                                htmlFor="email"
                              >
                                E-post
                              </label>
                              <input
                                className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
                                  this.state.errors.email ? 'border-red-500' : ''
                                }`}
                                id="email"
                                type="email"
                                name="email"
                                value={this.state.email}
                                onChange={this.handleInputChange}
                                placeholder="E-post"
                                required
                              />
                              {this.state.errors.email && (
                                <p className="text-red-500 text-xs italic">
                                  Vennligst sktriv inn epost.
                                </p>
                              )}
                            </div>
                            <div className="w-1/2 px-2">
                              <label
                                className="block text-gray-700 text-sm font-bold mb-2"
                                htmlFor="phone"
                              >
                                Telefonnummer
                              </label>
                              <input
                                className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
                                  this.state.errors.phone ? 'border-red-500' : ''
                                }`}
                                id="phone"
                                type="text"
                                name="phone"
                                value={this.state.phone}
                                onChange={this.handleInputChange}
                                placeholder="Telefonnummer"
                                required
                              />
                              {this.state.errors.phone && (
                                <p className="text-red-500 text-xs italic">
                                  Vennligst sktriv inn telefonnummer.
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="mb-4">
                          <label
                            className="block text-gray-700 text-sm font-bold mb-2"
                            htmlFor="message"
                          >
                            Melding
                          </label>
                          <textarea
                            className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
                              this.state.errors.email ? 'border-red-500' : ''
                            }`}
                            id="message"
                            rows="5"
                            placeholder="Melding"
                            name="message"
                            value={this.state.message}
                            onChange={this.handleInputChange}
                            required
                          />
                          {this.state.errors.message && (
                            <p className="text-red-500 text-xs italic">Vennligst sktriv inn melding.</p>
                          )}
                        </div>
                        <button
                          aria-label="Send inn kontaktskjema"
                          className="bg-blue-700  flex items-center outline-none transition-all transition-250 hover:bg-blue-900 text-white py-2 px-10 rounded-full"
                          type="submit"
                          onClick={this.handleSubmit}
                        >
                          <ClipLoader
                            sizeUnit={'px'}
                            size={21}
                            color={'#FFF'}
                            loading={this.state.busy}
                            className="ff"
                          />
                          {!this.state.busy && <span>Send inn</span>}
                        </button>
                      </form>
                    </div>
                  </div>
                </motion.div>
              </div>
            </div>
          </div>
        </VisibilitySensor>
      </Layout>
    );
  }
}
